import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import handleHospital from "@/services/modules/hospital";
import useDate from '@/services/utils/day';
import { chunkArray, formatDateToDdMmYy, joinArrayOfString } from '@/services/utils/global';
import { startCase, head, last } from 'lodash';

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const pdfPrinter = () => {
    const { formatDate } = useDate();
    const { calculateAge } = handleHospital();

    const exportToPDF = (company, admissionInfo, dischargeNotes, formData, qrcode, userName, barcodePatient, barcodeAdmission, notesOrder, dischargeModes) => {
        var doc = {
            pageSize: 'A4',
            pageMargins: [ 100, 100, 60, 90 ],
            header: (currentPage, pageCount, pageSize) => (currentPage == 1) ? getHeader(company) : '',
            footer: (currentPage, pageCount, pageSize) => (currentPage == pageCount) ? getFooter(userName, qrcode) : '',
            content: getContent(doc, admissionInfo, dischargeNotes, formData, barcodePatient, barcodeAdmission, notesOrder, dischargeModes),
            
            styles : {
                header: {
                    fontSize: 24,
                }
            },

            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },

            info: {
                title: 'DischaregeOrder'
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (doc, admissionInfo, dischargeNotes, formData, barcodePatient, barcodeAdmission, notesOrder, dischargeModes) => {
        return [
            {
                text: 'Discharge Certificate',
                style: 'header',
                alignment: 'center',
                margin: [0, 50, 20, 10],
                bold: true,
                color: 'black',
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                svg: barcodePatient
                            },

                            {
                                text: `Patient Name: ${admissionInfo.patient.full_name}`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `Patient ID: ${admissionInfo.patient.serial_no}`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `${(admissionInfo.patient && admissionInfo.patient.birthday) ? 
                                        'Age: ' + calculateAge(admissionInfo.patient.birthday) : ''}` + 
                                        `${(admissionInfo.patient && admissionInfo.patient.gender) ? 
                                        ' | Sex: ' + admissionInfo.patient.gender : '' }`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `Patient Phone No: ${admissionInfo.patient.mobile_no}`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `${admissionInfo.patient.full_address ? 'Patient Address: ' + admissionInfo.patient.full_address : ''}`,
                                alignment: 'left',
                                width: '50%'
                            },
                        ]
                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                svg: barcodeAdmission
                            },

                            {
                                text: `Admission No: ${admissionInfo.admission_no}`
                            },

                            {
                                text: `Admission Date: ${admissionInfo.formattedAdmissionData}`
                            },

                            {
                                text: `Discharge Date: ${formatDate(new Date(formData.dischargeDate))}`
                            }
                        ]
                    },
                ]
            },

            {
                canvas: [
                    {
                        type: 'line',
                        x1: 0, 
                        y1: 0, 
                        x2: 400,
                        y2: 0,
                    },
                ],
                margin: [0, 5]
            },

            getNotesOnAdmission(admissionInfo, formData),

            ... pushIntoTable(doc, notesOrder.investigations),

            {
                width: "50%",
                text: 'OT Notes',
                fontSize: 12,
                bold: true,
                decoration: 'underline',
                margin: [0, 5]
            },

            ...getOtNotes(notesOrder.otNotes),

            {
                width: "50%",
                text: 'Baby Notes',
                fontSize: 12,
                bold: true,
                decoration: 'underline',
                margin: [0, 5]
            },

            ...getBabyNotes(notesOrder.babyNotes),

            {
                text: 'Discharge Medications',
                fontSize: 12,
                bold: true,
                decoration: 'underline',
                margin: [0, 5]
            },

            {
                text: 'Rx',
                bold: true,
                decoration: 'underline',
                margin: [5, 0]
            },

            getRx(dischargeNotes.rx.selectedNotes),

            {
                margin: [0, 5, 0, 0],
                text: `Mode of Discharge: ${ getDischargeMode(dischargeModes, formData)}`
            },

            {
                margin: [0, 5, 0, 0],
                text: 'OPD Follow up visit: '
            },

            getFollowUp(dischargeNotes.follow_up.selectedNotes),

            {
                margin: [0, 5, 0, 0],
                text: 'Investigation Required: '
            },

            getInvestigation(dischargeNotes.investigation.selectedNotes),

            {
                stack: [
                    {
                        text: 'Discharge Note',
                        fontSize: 12,
                        bold: true,
                        decoration: 'underline',
                        margin: [0, 5]
                    },

                    getDisChargeNotes(dischargeNotes.discharge_note.selectedNotes)
                ]
            }
        ]
    }

    const getDischargeMode = (dischargeModes, formData) => {
        const singleMode = dischargeModes.find(item => item.value === formData.mode_of_discharge);
        if(!singleMode) return '';

        return singleMode.name;
    }

    const pushIntoTable = (doc, investigations) => {
        const tableTitle = getInvestigationTitle();
        const tableHeader = getTableHeader()
        
        const content = {
            style: 'tableExample',
            margin: [0, 5],
            table: {
                widths: ['*', '15%', '15%', '15%', '15%', '15%'],
                body: []
            },
            layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                  return '';
                },
                hLineWidth: function (i, node) {
                  return 0.5;
                },
                vLineWidth: function (i, node) {
                  return 0;
                },
                hLineColor: function (i, node) {
                  return ''; 
                },
                vLineColor: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? '#000000' : '#dddddd';
                },
            }
        }

        content.table.body.push(tableHeader);

        for(const item of investigations){
            content.table.body.push(getRowData(item))
        }

        return [
            tableTitle,
            content
        ];
    }

    const getInvestigationTitle = () => {
        return {
            width: "50%",
            text: 'Investigation Details',
            fontSize: 12,
            bold: true,
            decoration: 'underline',
            margin: [0, 5, 0, 0]
        }
    }

    const getRowData = (item) => {
        return [ 
            { text: item.latestResult.product_name || item.oldResult.product_name },
            { text: item.latestResult.name || item.oldResult.name  },
            { text: item.latestResult.unit || item.oldResult.unit },
            { text: item.oldResult.result },
            { text: item.otherResults.length ? item.otherResults[0].result : '' },
            { text: item.otherResults.length > 1 ? last(item.otherResults).result : '' } 
        ];
    }

    const getTableHeader = () => {
        return [
            { text: 'Name of investigation', bold: true },
            { text: 'Investigation Head', bold: true },
            { text: 'Unit', bold: true },
            { text: 'Previous Result', bold: true },
            { text: 'After Admission', bold: true },
            { text: 'During Discharge', bold: true }
        ];
    }

    const getRx = (notes) => {
        var ol = [];
        notes.map((el) => {
            const text = `${el.name} \n ${ el.dose ? el.dose : ''} \t ${el.unit ? el.unit : ''} \t ${el.remarks ? el.remarks : ''} \t ${el.duration ? el.duration : ''}`;
            ol.push(text);
        })
        return {
            margin: [10, 5, 0, 0],
            ol
        }
    }

    const getInvestigation = (notes) => {
        var ol = [];
        notes.map((el) => {
            ol.push(el.name)
        })
        return {
            margin: [10, 5, 0, 0],
            ol
        }
    }

    const getFollowUp = (notes) => {
        var ol = [];
        notes.map((el) => {
            ol.push(el)
        })
        return {
            margin: [10, 5, 0, 0],
            ol
        }
    }

    const getBabyNotes = (babyNotes) => {
        
        const chunkNotes = chunkArray(babyNotes, 2);
        const formattedNotes = [];

        for(const notes of chunkNotes){
            const newNotes = notes.map((note) => {

                return {
                    width: '50%',
                    stack: [
                        {
                            text: `Gender: ${ startCase(note.gender) }`,
                            color: 'black',
                            bold: true,
                        },
                        {
                            text: `Weight: ${ note.weight }`,
                            color: 'black',
                            bold: true,
                        },
                        {
                            text: `APGAR Score: ${ note.apgar_score }`,
                            color: 'black',
                            bold: true,
                        },
                        {
                            text: `Special Note: ${ convertArraytoString(note.notes) }`,
                            color: 'black',
                            bold: true,
                        }
                    ]
                }
            })
            
            formattedNotes.push({margin: [0, 5], columns: newNotes})
        }

        return formattedNotes;
    }

    const convertArraytoString = (notes) => {
        const note = notes.map(obj => obj.note).flat();
        return joinArrayOfString(note);
    }

    const getOtNotes = (otNotes) => {
        
        const chunkNotes = chunkArray(otNotes, 2);
        const formattedNotes = [];

        for(const notes of chunkNotes){
            const newNotes = notes.map((note) => {

                const consultants = formatOTNote(note);

                return {
                    width: '50%',
                    stack: [
                        {
                            text: `OT Date & Time: ${formatDateToDdMmYy(note.operation_date)}`,
                            color: 'black',
                            bold: true,
                        },
                        {
                            text: `Name of Operation: ${note.product.name}`,
                            color: 'black',
                            bold: true,
                        },
                        note.indication ? {
                            text: `Indication: ${startCase(note.indication)}`,
                            color: 'black',
                            bold: true,
                        } : '',
                        consultants.surgeon.length ? {
                            text: `Surgeon: ${joinArrayOfString(consultants.surgeon)}`,
                            color: 'black',
                            bold: true,
                        } : '',
                        consultants.ass_surgeon.length ? {
                            text: `Asst. Surgeon: ${joinArrayOfString(consultants.ass_surgeon)}`,
                            color: 'black',
                            bold: true,
                        } : '',
                        consultants.anestesiologist.length ? {
                            text: `Anestesiologist: ${joinArrayOfString(consultants.anestesiologist)}`,
                            color: 'black',
                            bold: true,
                        } : '',
                        consultants.nurse.length ? {
                            text: `Nurse: ${joinArrayOfString(consultants.nurse)}`,
                            color: 'black',
                            bold: true,
                        } : ''
                    ]
                }
            })
            formattedNotes.push({margin: [0, 5], columns: newNotes})
        }

        return formattedNotes;
    }

    const formatOTNote = (note) => {
        const consultants = {
            surgeon: [],
            anestesiologist: [],
            ass_surgeon: [],
            nurse: [],
        };
        
        for(const item of note.ot_order_service){
            consultants[item.pivot.type].push(item.name);
        }

        return consultants;
    }

    const getNotesOnAdmission = (admissionInfo, formData) => {
        return {
            margin: [0, 5],
            columns: [
                {
                    width: '40%',
                    stack: [
                        {
                            text: 'Notes on Admission',
                            fontSize: 12,
                            bold: true,
                            decoration: 'underline',
                            margin: [0, 5]
                        },
                        {
                            text: `Admitted Department: ${admissionInfo.department ? admissionInfo.department.name : ''}`
                        },
                        {
                            text: `Ward / bed / Cabin no: ${admissionInfo.service_resource.name}`
                        },
                        {
                            text: `Admitted date & time: ${admissionInfo.formattedAdmissionData}`
                        },
                        {
                            text: `Suffering from: ${formData.suffering_from}`
                        } 	
                    ]
                },

                {
                    stack: [
                        {
                            text: 'Admitting Consultant',
                            fontSize: 12,
                            bold: true,
                            decoration: 'underline',
                            margin: [0, 5]
                        },
                        {
                            text: admissionInfo.admitted_by_user.full_name
                        }
                    ]
                },

                {
                    stack: [
                        {
                            text: 'Treating Physicians',
                            fontSize: 12,
                            bold: true,
                            decoration: 'underline',
                            margin: [0, 5]
                        },
                        {
                            text: admissionInfo.human_resource.name
                        }
                    ]
                }
            ]
        }
    }

    const getDisChargeNotes = (notes) => {
        var ol = [];
        notes.map(el => {
            ol.push(el) 
        })
        return {
            margin: [10, 5, 0, 0],
            ol
        }
    }

    const getHeader = (company) => {
        return {
            margin: [ 100, 70, 60, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60,
                    maxWidth: 60
                },

                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email
                    ]
                }
            ]
        }
    }

    const getFooter = (userName, qrcode) =>{
        return {
            margin: [ 120, -30, 72, 96 ],
            columns: [
                {
                    columns: [
                        {
                            svg: qrcode
                        },

                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 40, 0, 0],
                            fontSize: 8
                        }
                    ]
                },

                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    return {
        exportToPDF
    }
}

export default pdfPrinter;